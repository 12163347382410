@if (isPaymentRequired$ | async) {
  @if (hasSubscription$ | async) {
    @let currentPackage = package();
    @let currentPrice = currentPrice$ | async;
    @let nextPaymentPrice = nextPaymentPrice$ | async;
    @if (currentPackage) {
      <section class="tw-container tw-flex tw-flex-col tw-gap-6">
        <section class="tw-flex tw-flex-col tw-gap-4">
          <div class="tw-flex tw-gap-2 tw-font-medium">
            <small>{{ 'profile.settings.payments.plan' | translate }}: {{ activePlanName() | titlecase }}</small>
            <small>
              {{ 'profile.settings.payments.next_payment' | translate }}: {{ nextPaymentPrice?.data?.amount }}{{ currentPrice?.currency }}
            </small>
          </div>
          <div class="tw-inline-grid tw-grid-cols-3 tw-gap-8 tw-w-fit">
            <div class="tw-flex tw-flex-col">
              <small>{{ 'profile.settings.payments.status' | translate }}:</small>
              <small>{{ currentPackage.stripe_status | titlecase }}</small>
            </div>
            <div class="tw-flex tw-flex-col">
              <small>{{ 'profile.settings.payments.price' | translate }}:</small>
              <small>{{ currentPrice?.current_price }}{{ currentPrice?.currency }} /{{ 'payments.plan.month' | translate }}</small>
            </div>
            <div class="tw-flex tw-flex-col">
              <small>{{ 'profile.settings.payments.trial_ends' | translate }}:</small>
              <small>{{ (currentPackage.trial_ends_at | date: 'dd/MM/YYYY') ?? '---' }}</small>
            </div>
          </div>
          <div class="tw-flex tw-gap-2">
            <button mat-raised-button color="primary" (click)="upgradeToActiveService()">
              {{ 'profile.settings.payments.upgrade_to_active_service' | translate }}
            </button>
          </div>
        </section>

        <p class="tw-font-medium">{{ 'profile.settings.payments.resources' | translate }}</p>
        <app-payments-settings-resources [data]="restrictions()"></app-payments-settings-resources>

        <a target="_blank" [href]="currentPackage.customerPortalUrl" class="tw-no-underline tw-text-blue-600 tw-self-start">
          {{ 'profile.settings.payments.payment_details' | translate }}
        </a>
      </section>
    }
  } @else {
    {{ 'profile.settings.payments.no_subscription' | translate }}
  }
} @else {
  {{ 'payments.current_package.disabled_payment' | translate }}
}
