import { Component, EventEmitter, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/notification/notification.service';
import { SettingsService } from 'src/app/shared/service/settings.service';
import { LangEnum, TranslationService } from 'src/app/shared/service/translation.service';
import { FormStatusService } from '../../../form-status.service';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent {
  @Output() closePanel = new EventEmitter<void>();
  readonly languages = [
    { value: LangEnum.en, key: 'languages.english' },
    { value: LangEnum.pl, key: 'languages.polish' },
  ];

  readonly #notificationService = inject(NotificationService);
  readonly #settingsService = inject(SettingsService);
  readonly #translationService = inject(TranslationService);
  readonly #formStatusService = inject(FormStatusService);

  readonly languageControl = new FormControl(this.#translationService.storageLang, {
    nonNullable: true,
    validators: [Validators.required],
  });

  constructor() {
    this.languageControl.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.#formStatusService.isAnyFormDirty = this.languageControl.dirty;
    });
  }

  protected saveChangeLanguage() {
    const lang = this.languageControl.value;

    this.#settingsService.changeLanguage(lang).subscribe(
      () => {
        this.#translationService.setCustomLang(lang);
        this.#notificationService.success('change_language');
        this.#formStatusService.notifySaveChanges();
      },
      () => this.#notificationService.error('change_language'),
    );
  }
}
