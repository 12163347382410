<table>
  <thead>
    <th>{{ 'profile.settings.payments.resources' | translate }}</th>
    <th>{{ 'profile.settings.payments.resources_left' | translate }}</th>
    <th>{{ 'profile.settings.payments.upgrade' | translate }}</th>
  </thead>
  <tbody>
    @for (item of data(); track item) {
      <tr>
        <td>{{ 'payments.current_package.' + item.name | translate }}</td>
        <td>
          @let color = +item.used | limitColor: item.limit;
          <span [style.background]="color" class="tw-px-2 tw-rounded-2xl">{{ item.used }}</span> / {{ item.limit }}
        </td>
        <td>
          <button mat-raised-button (click)="goToPayment()">{{ 'profile.settings.payments.increase_resources' | translate }}</button>
        </td>
      </tr>
    }
  </tbody>
</table>
