import { AsyncPipe, DatePipe, TitleCasePipe } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, startWith, switchMap, tap } from 'rxjs';
import { PaymentsService } from 'src/app/dashboard/payments/payments.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { isPresent } from 'src/app/shared/helpers/utils';
import { AuthService } from 'src/app/shared/service/auth.service';
import { PaymentSettingsResource } from './models';
import { PaymentsSettingsResourcesComponent } from './payments-settings-resources/payments-settings-resources.component';

@Component({
  selector: 'app-payments-settings',
  standalone: true,
  imports: [MatButtonModule, PaymentsSettingsResourcesComponent, MatIconModule, DatePipe, TitleCasePipe, TranslateModule, AsyncPipe],
  templateUrl: './payments-settings.component.html',
})
export class PaymentsSettingsComponent {
  private readonly paymentsService = inject(PaymentsService);
  private readonly notificationService = inject(NotificationService);
  private readonly authService = inject(AuthService);
  readonly isPaymentRequired$ = this.authService.authUser$.pipe(
    startWith(this.authService.authUser),
    map((user) => user?.is_payment_required ?? false),
  );

  readonly hasSubscription$ = this.authService.authUser$.pipe(
    startWith(this.authService.authUser),
    map((user) => user?.has_subscription ?? false),
  );

  readonly currentPrice$ = this.paymentsService.getCurrentPrice();
  readonly nextPaymentPrice$ = this.paymentsService.getNextPaymentPrice();
  readonly package = toSignal(this.paymentsService.getCurrentPackage());

  readonly activePlanName = computed(() =>
    this.package()
      ?.items.find((item) => item.type === 'support')
      ?.prod_name?.replace('_', ' '),
  );

  readonly restrictions = computed(() => {
    const currentPackage = this.package();

    return currentPackage
      ? ([
          {
            name: 'output_products',
            used: currentPackage.used?.output_products ?? '0',
            limit: currentPackage.restrictions.output_products,
          },
          { name: 'projects', used: currentPackage.used?.projects ?? '0', limit: currentPackage.restrictions.projects },
          {
            name: 'monitor_phrases',
            used: currentPackage.used?.monitor_phrases ?? '0',
            limit: currentPackage.restrictions.monitor_phrases,
          },
          {
            name: 'monitor_products',
            used: currentPackage.used?.monitor_products ?? '0',
            limit: currentPackage.restrictions.monitor_products,
          },
        ] satisfies PaymentSettingsResource[])
      : [];
  });

  upgradeToActiveService() {
    this.paymentsService
      .getPlan()
      .pipe(
        map((plan) => plan.basic?.id),
        filter(isPresent),
        switchMap((activeServicePlanId) =>
          this.paymentsService
            .changePackage(activeServicePlanId)
            .pipe(tap(() => this.notificationService.success('upgrade_to_active_service'))),
        ),
      )
      .subscribe();
  }
}
