<form [formGroup]="form" id="form">
  <mat-form-field>
    <mat-label>
      {{ 'profile.settings.password.new_label' | translate }}
    </mat-label>
    <input [formControl]="password" cdkFocusInitial matInput name="password" type="password" class="sentry-mask" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      {{ 'profile.settings.password.confirmation_label' | translate }}
    </mat-label>
    <input [formControl]="password_confirmation" matInput name="password_confirmation" type="password" class="sentry-mask" />
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>

  <button (click)="saveChangePassword()" [disabled]="isLocked || !form.valid" color="primary" mat-raised-button>
    {{ 'general.save' | translate }}
  </button>
</form>
