import { Component, inject } from '@angular/core';
import { AppFormFieldErrorDisplayComponent } from '../../../../../shared/components/app-form-field-error-display/app-form-field-error-display.component';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MaterialModule } from '../../../../../shared/material-module/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ProjectNotificationsModel } from '../../../../../shared/model/project-notifications.model';
import { NotificationService } from '../../../../../notification/notification.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    AppFormFieldErrorDisplayComponent,
    MatButton,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MaterialModule,
    TranslateModule,
    MatFormFieldModule,
    CommonModule,
    MatTableModule,
    MatSlideToggleModule,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  private notificationService = inject(NotificationService);

  displayedColumns: string[] = ['project', 'domain', 'notification'];
  projects$: Observable<ProjectNotificationsModel[]> = this.notificationService.getProjectsForNotifications();

  onNotificationChange(project: ProjectNotificationsModel, event: MatSlideToggleChange) {
    const isEnable = event.checked;
    const action = isEnable
      ? this.notificationService.enableProjectNotifications(project.id)
      : this.notificationService.disableProjectNotifications(project.id);

    action.subscribe({
      next: () => this.notificationService.success('notification_updated'),
      error: () => this.notificationService.error('notification_updated'),
    });
  }
}
