import { Pipe, PipeTransform } from '@angular/core';

const infoColor = '#C1F6D7';
const warningColor = '#5E4200';
const errorColor = '#F6D7D6';

@Pipe({ name: 'limitColor', standalone: true })
export class ResourceLimitColorPipe implements PipeTransform {
  transform(value: number, limit: string | null) {
    if (!limit) {
      return 'white';
    }
    const used = value / +limit;

    if (used <= 0.75) {
      return infoColor;
    } else if (used <= 0.9) {
      return warningColor;
    }
    return errorColor;
  }
}
