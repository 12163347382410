import { Component, OnDestroy, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CanDeactivateCheck } from '../../../shared/model/can-deactivate-check.interface';
import { FormStatusService } from '../form-status.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements CanDeactivateCheck, OnDestroy {
  protected isDirty: boolean = false;
  #destroy$ = new Subject<void>();
  #formStatusService = inject(FormStatusService);

  constructor() {
    this.#formStatusService.isAnyFormDirty$.pipe(takeUntil(this.#destroy$)).subscribe((isDirty) => {
      this.isDirty = isDirty;
    });
    this.#formStatusService.saveChanges$.pipe(takeUntil(this.#destroy$)).subscribe(() => {
      this.isDirty = false;
    });
  }

  hasUnsavedChanges(): boolean {
    return this.isDirty;
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
