import { Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { PaymentSettingsResource } from '../models';
import { ResourceLimitColorPipe } from './pipes';

@Component({
  selector: 'app-payments-settings-resources',
  standalone: true,
  imports: [MatButtonModule, TranslateModule, ResourceLimitColorPipe],
  templateUrl: './payments-settings-resources.component.html',
  styleUrl: './payments-settings-resources.component.scss',
})
export class PaymentsSettingsResourcesComponent {
  data = input<PaymentSettingsResource[]>([]);

  private readonly routerService = inject(RouterService);

  goToPayment() {
    this.routerService.navigate(routerConfig.paymentsPlan);
  }
}
